// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "./themes.scss";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// import our custom theme

@import "@angular/cdk/overlay-prebuilt.css";

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}
@font-face {
  font-family: "TTW";
  src: url("/assets/TTW.woff") format("woff"),
    url("/assets/TTW.eot?#iefix") format("embedded-opentype"),
    url("/assets/TTW.ttf") format("truetype"),
    url("/assets/TTW.svg#TTW") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*="ttw-"]:before {
  display: inline-block;
  font-family: "TTW";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ttw-air-con:before {
  content: "\0041";
}
.ttw-bar:before {
  content: "\0042";
}
.ttw-bbq:before {
  content: "\0043";
}
.ttw-cinema:before {
  content: "\0044";
}
.ttw-dining:before {
  content: "\0045";
}
.ttw-fireplace:before {
  content: "\0046";
}
.ttw-games-room:before {
  content: "\0047";
}
.ttw-gym:before {
  content: "\0048";
}
.ttw-hot-tub:before {
  content: "\0049";
}
.ttw-laundry:before {
  content: "\004a";
}
.ttw-mnt-view:before {
  content: "\004b";
}
.ttw-pets:before {
  content: "\004c";
}
.ttw-pool:before {
  content: "\004d";
}
.ttw-sauna:before {
  content: "\004e";
}
.ttw-ski-in-ski-out:before {
  content: "\004f";
}
.ttw-ski-lift:before {
  content: "\0050";
}
.ttw-spa:before {
  content: "\0051";
}
.ttw-steam-room:before {
  content: "\0052";
}
.ttw-tennis:before {
  content: "\0053";
}
.ttw-transfers:before {
  content: "\0054";
}
.ttw-valet-parking:before {
  content: "\0055";
}
.ttw-vacuum:before {
  content: "\0056";
}

@font-face {
  font-family: "social";
  src: url("/assets/social.eot");
  src: url("/assets/social.eot?#iefix") format("embedded-opentype"),
    url("/assets/social.woff") format("woff"),
    url("/assets/social.ttf") format("truetype"),
    url("/assets/social.svg#social") format("svg");

  font-weight: normal;
  font-style: normal;
}
[class*="social-"]:before {
  display: inline-block;
  font-family: "social";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.social-ttw-facebook:before {
  content: "\0042";
}
.social-ttw-instagram:before {
  content: "\0043";
}
.social-ttw-linkedin:before {
  content: "\0044";
}
.social-ttw-twitter-old:before {
  content: "\0045";
}
.social-ttw-youtube:before {
  content: "\0046";
}
.social-ttw-twitter:before {
  content: "\0047";
}
.social-ttw-tiktok:before {
  content: "\0048";
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Open Sans", Georgia, serif;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
}

/* Google Maps Styles */

.ttw-map-info-window-with-margin .gm-style .gm-style-iw-t {
  margin-bottom: 40px !important;
}
.ttw-map-info-window-with-complex-margin .gm-style .gm-style-iw-t {
  margin-bottom: 50px !important;
}

.gm-style-iw-chr {
  //display: none !important;
  z-index: 100;
  color: transparent !important;
  background-color: transparent !important;
}
.gm-style-iw-chr button {
  //.gm-ui-hover-effect
  z-index: 200;
  background-color: white !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  width: 24px !important;
  height: 24px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
}

.gm-style-iw-chr button.gm-ui-hover-effect {
  border-radius: 50% !important;
}

.gm-style-iw-chr button span {
  margin: 5px !important;
  width: 14px !important;
  height: 14px !important;
}
.gm-style .gm-style-iw-d {
  display: contents;
}
.gm-style-iw-chr .gm-style-iw-ch {
  z-index: 1;
  color: transparent !important;
  background-color: transparent !important;
}

.gm-style-iw-d {
  z-index: 20;
}
.gm-style .gm-style-iw-c {
  padding: 0 !important;
  max-height: 300px !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw {
  outline: none;
}

.gm-style iframe + div {
  border: none !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-label {
  white-space: initial;
}

.mat-mdc-menu-item {
  &::-moz-focus-inner {
    border: 0;
  }
}

.mat-mdc-dialog-container {
  border-radius: 12px !important;
}

.guest-dialog-container {
  .mat-mdc-dialog-content {
    overflow-y: hidden;
  }
}

/* Increase size of fullscreen image in ks89-gallery */
@media screen and (min-width: 1025px) {
  .current-image > img {
    min-height: 80vh !important;
    max-height: 80vh !important;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}
.mat-sidenav .mat-mdc-list-item .mdc-list-item__content span {
  font-family: "Lexend", sans-serif !important;
}
/*********** NEW MDS STYLE REWRITES ***************/

.close-button .mat-mdc-button-persistent-ripple {
  visibility: hidden;
}

.mat-mdc-button.no-hover:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 1;
  background-color: white;
}

.mat-expansion-panel-header-description {
  font-family: "Open Sans, Georgia, serif" !important;
  font-weight: 400 !important;
}
.guests-select .mat-mdc-option.mdc-list-item--disabled {
  pointer-events: auto !important;
}
.mat-mdc-input-element {
  font-family: "Lexend", sans-serif;
}

.mat-mdc-form-field {
  font-family: "Lexend", sans-serif;
}
.mat-mdc-select-value {
  font-family: "Lexend", sans-serif;
}
.mdc-text-field__input {
  font-family: "Lexend", sans-serif;
}

.search-container .mat-mdc-select-placeholder {
  font-family: "Lexend", sans-serif;
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 24px;
  opacity: 0.9;
  font-weight: normal;
}
.search-container .mat-mdc-select-value-text {
  font-family: "Lexend", sans-serif;
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 24px;
  opacity: 0.9;
  font-weight: normal;
}

.search-container .mat-mdc-datepicker-placeholder {
  font-family: "Lexend", sans-serif;
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 24px;
  opacity: 0.9;
  font-weight: normal;
}

input:focus {
  outline: none;
}

.complexes-paginator
  .mat-mdc-icon-button.mat-mdc-button-disabled
  .mat-mdc-paginator-icon {
  opacity: 0.6 !important;
}
.complexes-paginator .mat-mdc-paginator-icon {
  fill: white !important;
}

.modal-headline-close .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  margin: 12px !important;
}
.mdc-text-field--filled {
  background-color: unset !important;
}

.mat-mdc-option-multiple .mat-pseudo-checkbox {
  visibility: visible;
}

.mat-mdc-optgroup .mat-pseudo-checkbox {
  visibility: hidden;
}

.no-checkbox-select mat-pseudo-checkbox {
  visibility: hidden !important;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action
  .mat-mdc-chip-action-label {
  color: white !important;
}
.mat-mdc-chip-remove {
  color: white !important;
}
.mat-icon {
  vertical-align: middle;
}
.guests-select .mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.guests-select .mdc-list-item__primary-text {
  opacity: 1 !important;
}
.cdk-overlay-pane {
  width: fit-content !important;
}

.mat-select-panel {
  max-width: unset !important;
}

.mat-select-value {
  max-width: max-content !important; // <= SOLUTION HERE...
}

.mat-form-field-infix {
  width: fit-content !important; // <= and HERE...
}

.mat-mdc-card-outlined {
  border-width: 0 !important;
}
.mat-mdc-input-element {
  border-width: 0;
  background-color: transparent;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
}

.mat-mdc-button {
  font-family: "Lexend", sans-serif !important;
}

.mdc-text-field--filled {
  background-color: white;
}

/***********TOP SEARCH BAR SPECIAL STYLES s*************/
.search-container {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /*.mat-select-placeholder {
    opacity: 1;
    font-family: "Lexend", sans-serif;
    font-weight: normal;
    border: none;
    color: #555d5e;
    font-size: 15px;
    line-height: 25px;
  }*/
  .mat-mdc-input-element::placeholder {
    opacity: 1;
    font-family: "Lexend", sans-serif;
    font-weight: normal;
    border: none;
    color: #555d5e;
    font-size: 15px;
    line-height: 25px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  /*.mat-datepicker-input,
  .mat-select-value {
    opacity: 0.6;
    font-family: "Lexend", sans-serif;
    font-weight: normal;
    border: none;
    color: #555d5e;
    font-size: 15px;
    line-height: 25px;
  }*/
}

.loading-container-page {
  position: relative;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 20vh;
  margin-bottom: 0px;
  min-height: 80vh;
  .mat-mdc-progress-spinner {
    height: 100%;
    min-height: 100px;
    position: absolute;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.loading-container {
  position: relative;
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 0px;
  height: 100%;
  margin-top: 100px;

  .mat-mdc-progress-spinner {
    height: 100%;
    min-height: 100px;
    position: absolute;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.sidenav {
  .mat-nav-list .mat-mdc-list-item {
    .mat-icon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.bold-font {
  font-weight: bold !important;
}

.bolder-font {
  font-weight: bolder !important;
}

.italic-font {
  font-style: italic !important;
}

.mat-mdc-input-element {
  font-family: "Lexend", sans-serif !important;
}

.mat-calendar-content {
  padding: 0 16px 16px 16px !important;
}

.header-separator-left {
  padding-bottom: 30px;
}
.header-separator-center {
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-heading {
  text-decoration-thickness: 5px;
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  .main-image-container {
    height: 100%;
    margin-bottom: 90px;
    position: relative;
    box-sizing: border-box;
    .left-sub-container {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 501;
      background-color: rgba(0, 0, 0, 0.2);

      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .right-arrow-image:hover {
      transform: none !important;
    }
    .right-sub-container {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      z-index: 501;
      background-color: rgba(0, 0, 0, 0.2);
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .left-arrow-image:hover {
      transform: none !important;
    }
  }
}

.main-image-container {
  @media screen and (min-width: 769px) {
    margin-right: 20px !important;
  }

  #current-image {
    z-index: 13 !important;
    @media screen and (max-width: 750px) {
      max-height: 85vh !important;
      max-width: 100vw !important;
      width: auto !important;
    }
    .left-arrow-image:hover {
      transform: none !important;
    }
    @media screen and (min-width: 751px) {
      max-height: 85vh !important;
      max-width: 85vw !important;
      width: auto !important;
    }
    .right-arrow-image:hover {
      transform: none !important;
    }
  }
}

// top action panel
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-disabled {
  display: flex !important;
  margin-top: 10px;
  margin-bottom: 10px;
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    height: fit-content;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    border-bottom: solid 2px rgba(85, 93, 94, 0.16);
  }
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
  min-height: 60px;
}

.invisible-item-number {
  .mat-mdc-paginator-container {
    .mat-mdc-paginator-page-size {
      display: none !important;
      width: 0 !important;
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-page-size {
    font-size: 16px;
  }

  .mat-mdc-paginator-range-label {
    font-size: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-disabled {
    color: white !important;
    opacity: 0.6 !important;
  }
}

.ie-only {
  margin: 10%;
  font-size: 12m;
  line-height: 130%;
  color: black;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

@media screen and (max-height: 1023px) {
  twbooking-booking-dialog {
    .unit-rates-container {
      /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
      mat-dialog-content {
        max-height: calc(98vh - 100px);
      }
    }
  }
}
