@use "@angular/material" as mat;
@mixin cust-theme($theme) {
  $primary: map-get($theme, primary);

  $primary-color: mat.get-color-from-palette($theme, 800);

  --mat-optgroup-label-text-color: mat.get-color-from-palette(
    $primary,
    800
  ) !important;

  --mdc-snackbar-container-color: mat.get-color-from-palette(
    $primary,
    800
  ) !important;

  mat-sidenav-content {
    color: mat.get-color-from-palette($primary, 800);
  }

  --mdc-snackbar-container-shape: 12px !important;

  html,
  body {
    color: mat.get-color-from-palette($primary, "800");
  }

  .rates-chip {
    background-color: mat.get-color-from-palette($primary, 100) !important;

    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .rates-chip.mat-mdc-chip-selected {
    background-color: mat.get-color-from-palette($primary, 800) !important;
    color: white !important;
  }

  .rates-chip.mat-mdc-chip-selected .mdc-evolution-chip__graphic {
    display: none;
  }
  .rates-chip.mat-mdc-chip-selected .mdc-evolution-chip__text-label {
    padding-left: 12px;
  }
  @media screen and (max-width: 599px) {
    .rates-chip .mdc-evolution-chip__text-label {
      font-size: 11px !important;
    }
  }

  .mdc-list-item__primary-text {
    color: mat.get-color-from-palette($primary, 800) !important;
  }
  .mat-mdc-button {
    color: mat.get-color-from-palette($primary, 800);
  }
  .mat-calendar-period-button {
    color: mat.get-color-from-palette($primary, 800) !important;
  }
  .mat-mdc-input-element {
    color: mat.get-color-from-palette($primary, "A700") !important;
  }

  .mat-mdc-form-field {
    color: mat.get-color-from-palette($primary, "A700") !important;
  }
  .mat-mdc-select-value {
    color: mat.get-color-from-palette($primary, "A700") !important;
  }
  .mdc-text-field__input {
    color: mat.get-color-from-palette($primary, "A700") !important;
  }
  .mat-expansion-panel-header-title {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .ttw-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0.5%;
  }

  .dialogue-headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-container-column {
    display: flex;
    flex-direction: column; /* Arranges children in a column */
  }

  .brand-light-badge .mat-badge-content {
    background-color: mat.get-color-from-palette($primary, 600) !important;
    color: mat.get-color-from-palette($primary, 800) !important;
  }
  .lexend-font {
    font-family: "Lexend", sans-serif;
  }

  .unit-summary-button {
    font-family: "Lexend", sans-serif;
    font-weight: 500;
    text-decoration: none;

    @media screen and (max-width: 599px) {
      font-size: 2.9vw;
      line-height: 2.9vw;
    }
    @media screen and (min-width: 600px) and (max-width: 959px) {
      font-size: 1.4vw;
      line-height: 1.4vw;
    }
    @media screen and (min-width: 960px) and (max-width: 1919px) {
      font-size: 0.9vw;
      line-height: 0.9vw;
    }
    @media screen and (min-width: 1920px) and (max-width: 5000px) {
      font-size: 0.6vw;
      line-height: 0.6vw;
    }
  }

  .ttw-placeholder-color {
    color: mat.get-color-from-palette($primary, "A200") !important;
  }

  .ttw-placeholder-background {
    background-color: mat.get-color-from-palette($primary, "A200") !important;
  }

  .ttw-custom-toggle:hover {
    cursor: pointer;
  }

  .ttw-custom-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    mat-icon {
      margin-right: 5px;
      font-size: 16px;
      line-height: 16px;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 0;
    }
    .toggle-active {
      background-color: rgba(mat.get-color-from-palette($primary, 800), 0.2);
    }

    .toggle-central {
      color: mat.get-color-from-palette($primary, 800);
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 2px solid mat.get-color-from-palette($primary, 800);
      border-radius: 12px;

      font-size: 14px;
      line-height: 16px;
    }
    .toggle-left {
      color: mat.get-color-from-palette($primary, 800);
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 2px solid mat.get-color-from-palette($primary, 800);
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      font-size: 14px;
      line-height: 16px;
    }
    .toggle-right {
      color: mat.get-color-from-palette($primary, 800);
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      height: 100%;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-right: 2px solid mat.get-color-from-palette($primary, 800);
      border-top: 2px solid mat.get-color-from-palette($primary, 800);
      border-bottom: 2px solid mat.get-color-from-palette($primary, 800);
      font-size: 14px;
      line-height: 16px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .unit-summary-button:hover {
    background-color: mat.get-color-from-palette($primary, 600) !important;
    color: white !important;
  }

  .inquiry-dialog-container {
    position: sticky !important;
    bottom: 0;
    right: 0;
    max-width: 600px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 80vh !important;
    max-height: 80vh !important;
    padding-bottom: 0px;
  }

  .tooltip-container {
    width: 100%;
    max-width: 1200px;

    box-sizing: border-box;
    height: auto;
    background-color: #103753;

    padding-right: 25px;

    .tooltip-content {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      height: auto;
      background-color: #103753;
      color: white;
    }
  }

  .tooltip-container-small {
    width: 100%;
    max-width: 300px;

    box-sizing: border-box;
    height: auto;
    background-color: #103753;

    padding-right: 24px;
    padding-left: 24px;
    .tooltip-content {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      height: auto;
      background-color: #103753;
      color: white;
    }

    .tooltip-content-list {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      height: auto;
      background-color: #103753;
      color: white;
      display: flex;
      flex-direction: column;
      align-content: baseline;
      align-items: baseline;
    }
  }

  .search-dialog-container {
    position: sticky !important;
    bottom: 0;
    right: 0;
    max-width: 600px;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 88vh !important;
    padding-bottom: 0px;
  }
  .white-color {
    color: white !important;
  }

  .white-color p {
    color: white !important;
  }
  .white-border {
    border-color: white;
  }

  .new-brand-color {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .new-brand-hover-light {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .new-brand-hover-light:hover {
    color: mat.get-color-from-palette($primary, 600) !important;
  }
  .amenity-svg-icon {
    width: 24px;
    height: 24px;
    fill: mat.get-color-from-palette($primary, 800) !important;
    font-weight: 300;
  }

  /*
  mat-nav-list{
    a{
      color: mat.get-color-from-palette($primary, 800) !important;
    }

  }


  mat-sidenav-content{
   a{
      color: mat.get-color-from-palette($primary, 800) !important;
    }
  }*/

  .label-like {
    font-size: 11px;
    line-height: 13px;
  }

  /********************* TTW buttons *********************/
  .primary-button {
    border: 1px solid mat.get-color-from-palette($primary, 800);
    background-color: mat.get-color-from-palette($primary, 800);
    color: white !important;
  }

  .primary-button:hover {
    background-color: mat.get-color-from-palette($primary, 600);
    color: white !important;
  }

  .secondary-button {
    border: 1px solid mat.get-color-from-palette($primary, 800);
    background-color: mat.get-color-from-palette($primary, 200);
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .tertiary-button {
    background-color: mat.get-color-from-palette($primary, 200);
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .ttw-button {
    font-family: "Lexend", sans-serif;
    font-weight: 300;
    padding: 26px 16px !important;
    font-size: 14px;
    line-height: 18px;
    box-sizing: border-box;
  }

  .ttw-button:disabled {
    opacity: 80%;
  }

  .brand-button {
    background-color: mat.get-color-from-palette($primary, 800) !important;
    color: white !important;
    padding: 26px 16px !important;
    font-size: 14px;
    line-height: 18px;
  }

  .brand-button:hover {
    background-color: mat.get-color-from-palette($primary, 600);
    color: white !important;
  }

  .brand-button:disabled {
    background-color: #103753;
    opacity: 80%;
    color: white !important;
  }

  .brand-bordered-button {
    background-color: transparent;
    color: mat.get-color-from-palette($primary, 800);
    padding: 23px 13px !important;
    border-color: mat.get-color-from-palette($primary, 800) !important;
    border: 3px solid !important;
    border-radius: 12px !important;
    font-family: "Lexend", sans-serif;
    font-weight: 500;
  }

  .filters-transparent-button {
    background-color: transparent;
    color: mat.get-color-from-palette($primary, 800) !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: "Lexend", sans-serif;
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px !important;
    border: 2px solid mat.get-color-from-palette($primary, 800) !important;
  }

  .filters-transparent-button:active {
    cursor: pointer;
    background-color: transparent;
  }
  .filters-transparent-button:hover {
    background-color: transparent;
    cursor: pointer;
    opacity: 0.6;
  }
  .filters-transparent-button mat-icon {
    margin-right: 12px;
  }

  .ttw-transparent-button {
    background-color: transparent;
    color: mat.get-color-from-palette($primary, 800) !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-family: "Lexend", sans-serif;
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px !important;
    border: 2px solid mat.get-color-from-palette($primary, 800) !important;
  }
  .ttw-transparent-button:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  @media screen and (max-width: 319px) {
    /*Adjustments for galaxy fold*/
    .new-brand-button {
      background-color: #103753 !important;
      color: white !important;
      font-size: 14px !important;
      line-height: 24px !important;
      border-radius: 5px !important;
      width: fit-content;
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      font-family: "Lexend", sans-serif;
    }
  }
  .new-brand-button:disabled {
    background-color: #103753 !important;
    opacity: 80%;
    color: white !important;
  }

  .fixed-content {
    width: fit-content !important;
    height: fit-content;
  }
  .new-brand-button:hover {
    background-color: rgba(#103753, 0.8);
    color: white !important;
  }

  @media screen and (min-width: 320px) {
    .new-brand-button {
      background-color: #103753 !important;
      color: white !important;
      font-size: 16px;
      line-height: 26px;
      border-radius: 5px !important;
      width: fit-content;
      padding-left: 26px !important;
      padding-right: 26px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      font-family: "Lexend", sans-serif;
    }
  }

  /******************* END TTW buttons *********************/

  .ttw-projected {
    background-color: #ffffff;
    color: mat.get-color-from-palette($primary, 800) !important;
    border-radius: 5px;
    font-family: "Lexend", sans-serif;

    .mat-h1 {
      color: mat.get-color-from-palette($primary, 800) !important;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 0 !important;
      font-family: "Lexend", sans-serif;
    }
    mat-icon {
      margin-right: 6px;
    }
    .mat-subtitle-1 {
      font-family: "Open Sans", Georgia, serif;
      font-size: 12px;
      margin-bottom: 4px;
    }

    h1 {
      margin-top: 0 !important;
    }
  }

  .ttw-projected-paddings {
    @media screen and (max-width: 699px) {
      padding: 4px 6px;
    }
    @media screen and (min-width: 700px) {
      padding: 4px 8px;
    }
  }

  .discount-sticker {
    text-align: right;
    position: absolute;
    font-size: small;

    float: left;
    bottom: 25px;
    right: 15px;
    padding: 6px;
    margin: 0;
    z-index: 10;
  }

  .new-brand-background {
    background-color: mat.get-color-from-palette($primary, 800) !important;
  }

  .hint-color {
    color: rgba(mat.get-color-from-palette($primary, "A400"), 0.6);
  }

  .hint-border-color {
    border: 1px solid rgba(mat.get-color-from-palette($primary, "A400"), 0.3);
  }

  .disabled-color {
    color: rgba(mat.get-color-from-palette($primary, "A400"), 0.3);
  }

  .hint-border {
    border: 1px solid rgba(mat.get-color-from-palette($primary, "A400"), 0.3);
    border-radius: 12px;
  }

  .red-color {
    color: #e45720;
  }

  .red-border {
    border-color: #e45720;
  }

  .white-background {
    background-color: white;
  }

  .brand-accent-background {
    background-color: mat.get-color-from-palette($primary, 600) !important;
  }

  .brand-snackbar {
    background-color: mat.get-color-from-palette($primary, 800);
    color: white; /* Adjust text color as needed */
    border-radius: 12px !important; /* Add your desired border-radius */
  }

  .brand-dark-background {
    background-color: mat.get-color-from-palette($primary, "A700");
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label.mat-tab-label-active {
    background-color: mat.get-color-from-palette($primary, 300) !important;
  }
  .brand-dark-summary-background {
    background-color: mat.get-color-from-palette($primary, "A400");
  }

  .brand-dark-caret {
    caret-color: mat.get-color-from-palette($primary, "A700");
  }

  .brand-offwhite-background {
    background-color: mat.get-color-from-palette($primary, 100);
  }

  .ttw-app-background {
    background-color: #fafafa;
  }

  .brand-offwhite-border {
    border-color: mat.get-color-from-palette($primary, 100) !important;
  }

  .brand-dark-border {
    border-color: mat.get-color-from-palette($primary, "A700") !important;
  }
  .brand-accent-border {
    border-color: mat.get-color-from-palette($primary, 600) !important;
  }

  .new-brand-border {
    border-color: mat.get-color-from-palette($primary, 800) !important;
  }

  /********************** MENU **************************/

  mat-expansion-panel.mobile-menu-expansion-panel {
    border-radius: 0px !important;
  }

  .mobile-menu-item {
    padding-left: 40px;
    padding-right: 40px;
  }

  .mobile-menu-expansion-panel .mat-expansion-panel-body {
    padding-bottom: 0px !important;
  }

  .mobile-menu-expansion-panel .mat-mdc-menu-item-text {
    line-height: 24px !important;
    font-size: 16px !important;
    padding-left: 16px;
  }
  .mat-mdc-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    //border-top: 3px solid;
    //border-color: mat.get-color-from-palette($primary, 600) !important;
    min-width: 220px;
  }

  .tw-toolbar-buttons-container
    .mat-mdc-button.mat-mdc-button-base
    .mdc-button__ripple {
    display: none !important;
  }

  .mat-mdc-menu-item {
    background-color: white;
    border-bottom: 1px solid !important;
    border-color: mat.get-color-from-palette($primary, 300) !important;
    padding-right: 20px;
    color: mat.get-color-from-palette($primary, 800) !important;
  }
  .mat-mdc-menu-item mat-icon {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  button.mat-mdc-menu-item {
    border-radius: 0px !important;
  }

  .mat-mdc-menu-item:hover {
    background-color: mat.get-color-from-palette($primary, 600) !important;
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .safari-menu.mat-mdc-menu-item:hover {
    background-color: #d1cab8 !important;
  }

  .brand-light-background {
    background-color: mat.get-color-from-palette($primary, 300) !important;
  }

  .brand-dark-opacity-16-background {
    background-color: mat.get-color-from-palette($primary, 900);
  }

  .brand-light-accent {
    color: mat.get-color-from-palette($primary, 500);
  }

  .brand-border-16 {
    border-color: mat.get-color-from-palette($primary, 900) !important;
  }

  .brand-accent-color {
    color: mat.get-color-from-palette($primary, 600);
  }

  .brand-dark-color {
    color: mat.get-color-from-palette($primary, "A700") !important;
  }

  .brand-show-more-color {
    color: mat.get-color-from-palette($primary, 700) !important;
  }

  .brand-offwhite-color {
    color: mat.get-color-from-palette($primary, 100);
  }

  .brand-offwhite-border {
    border-color: mat.get-color-from-palette($primary, 100);
  }

  .brand-accent-border {
    border-color: mat.get-color-from-palette($primary, 600) !important;
  }

  .brand-small-button {
    background-color: mat.get-color-from-palette($primary, 800) !important;
    color: white !important;
    padding: 16px 16px !important;
  }
  .brand-small-button:hover {
    background-color: mat.get-color-from-palette($primary, 600);
    color: white !important;
  }

  .border-bottom-opacity-16 {
    border-bottom: solid 2px mat.get-color-from-palette($primary, 900);
  }

  .standard-text {
    font-family: "Open Sans", Georgia, serif;
    color: mat.get-color-from-palette($primary, 800);
    font-size: 16px;
    line-height: 26px;
  }

  .small-text {
    font-family: "Open Sans", Georgia, serif;
    color: mat.get-color-from-palette($primary, "A700");
    font-size: 14px;
    line-height: 24px;
  }

  .review-stars {
    color: mat.get-color-from-palette($primary, 600);
  }

  .review-stars-light {
    color: mat.get-color-from-palette($primary, 500);
  }

  .icon-clickable {
    border-bottom: 2px solid mat.get-color-from-palette($primary, 800);
    padding: 2px;
  }
  .icon-clickable:hover {
    cursor: pointer;
  }
  .text-clickable:hover {
    cursor: pointer;
  }

  .tw-snackbar-container {
    background: mat.get-color-from-palette($primary, 800) !important;
    color: mat.get-color-from-palette($primary, 50) !important;
    font-family: "Lexend", sans-serif;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .tw-snackbar-container .mat-chip-remove {
    color: mat.get-color-from-palette($primary, 50) !important;
  }

  .dark-uncolored-button {
    color: mat.get-color-from-palette($primary, 800);
    border: 2px solid mat.get-color-from-palette($primary, 800);
    border-radius: 12px;
  }
  .dark-uncolored-button:hover {
    border: 2px solid mat.get-color-from-palette($primary, "A100");
    color: mat.get-color-from-palette($primary, 800);
    opacity: 0.6;
    border-radius: 12px;
  }
  .main-tile {
    border-bottom: 2px solid mat.get-color-from-palette($primary, "A700") !important;
    border-radius: 0px !important;
    opacity: 1;
  }

  .grid-tile {
    border-bottom: 2px solid;
    border-color: rgba(16, 55, 83, 0.5) !important;
    border-radius: 0px !important;
  }
  .grid-tile:hover {
    border-bottom: 2px solid;
    border-color: rgba(16, 55, 83, 1) !important;
  }

  .figure-shadow {
    border-radius: 12px !important;
    box-shadow: 0 3px 6px mat.get-color-from-palette($primary, 900) !important;
  }

  .accent-shadow {
    border-radius: 12px !important;
    box-shadow: 0 5px 10px mat.get-color-from-palette($primary, 900) !important;
  }

  .unit-summary-card-shadow {
    border-radius: 12px !important;
    box-shadow: 6px 6px 30px mat.get-color-from-palette($primary, 900) !important;
    top: 0;
    transition: top ease 0.5s;
  }
  .unit-summary-card-shadow:hover {
    border-radius: 12px !important;
    box-shadow: 6px 10px 40px mat.get-color-from-palette($primary, "A100") !important;
    top: -5px !important;
    transition: 0.5s;
  }

  .brand-dark-transparent-background {
    background-color: mat.get-color-from-palette($primary, "A100");
  }

  .unit-geo-card-shadow {
    border-radius: 12px !important;
    box-shadow: 0 3px 6px mat.get-color-from-palette($primary, 900) !important;
  }

  .unit-geo-card-shadow:hover {
    border-radius: 12px !important;
    box-shadow: 0 5px 10px mat.get-color-from-palette($primary, "A100") !important;
  }

  .complex-summary-card-shadow {
    border-radius: 12px !important;
    box-shadow: 0 3px 6px mat.get-color-from-palette($primary, 900) !important;

    twbooking-complex-summary {
      .unit-summary-card {
        .brand-dark-summary-background-shadow {
          background-color: mat.get-color-from-palette($primary, "A400");
        }
      }
    }
  }
  .complex-summary-card-shadow:hover {
    border-radius: 12px !important;
    box-shadow: 0 5px 10px mat.get-color-from-palette($primary, "A100") !important;

    twbooking-complex-summary {
      .unit-summary-card {
        background-color: white;
        .brand-dark-summary-background-shadow {
          background-color: mat.get-color-from-palette(
            $primary,
            "A100"
          ) !important;
        }
        .summary-info-container {
          .brand-dark-summary-background-shadow {
            background-color: mat.get-color-from-palette(
              $primary,
              "A100"
            ) !important;
          }
        }
      }
    }
  }

  .complex-summary-card-shadow {
    border-radius: 12px !important;
    box-shadow: 6px 6px 30px mat.get-color-from-palette($primary, 900) !important;
  }
  .complex-summary-card-shadow:hover {
    border-radius: 12px !important;
    box-shadow: 6px 10px 40px mat.get-color-from-palette($primary, "A100") !important;
    .twbooking-complex-summary {
      background-color: mat.get-color-from-palette($primary, "A100") !important;
    }
  }

  .top-panel-item {
    color: mat.get-color-from-palette($primary, 800) !important;
    background-color: white !important;
  }

  .side-nav-item {
    color: mat.get-color-from-palette($primary, 800) !important;
  }

  .top-panel-item:hover {
    background-color: white !important;
    border-top: 4px solid;
    border-color: mat.get-color-from-palette($primary, 600) !important;
    color: mat.get-color-from-palette($primary, 600) !important;
  }
  .possible-transport:hover {
    background-color: mat.get-color-from-palette($primary, 300);
  }
  .left-summary-block:hover {
    background-color: mat.get-color-from-palette($primary, 400);
    border: 1px solid;
    cursor: pointer;
    border-color: mat.get-color-from-palette($primary, 600) !important;
  }

  .plus-minus-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0 !important;
    margin: 0 !important;
    border: 1px solid mat.get-color-from-palette($primary, 800);
    -moz-border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }

  .plus-minus-button span {
    display: block;
    line-height: 1;
    color: mat.get-color-from-palette($primary, "A700");
  }

  .plus-minus-button:hover {
    cursor: pointer;
    border: solid 1px mat.get-color-from-palette($primary, "A700");
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-label {
    color: mat.get-color-from-palette($primary, "A100") !important;
    font-family: "Open Sans", Georgia, serif;
  }

  .mat-mdc-button:hover {
    border-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-color-from-palette($primary, 500);
  }

  /*  [class*="ttw-"]:before.light-icons {
    color: mat-color($primary, 500);
    }
  

  [class*="ttw-"]:before {
    color: mat-color($primary, 600);
  }*/

  [class*="social-ttw-"]:before {
    color: white;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: mat.get-color-from-palette($primary, 800) !important;
    background-color: mat.get-color-from-palette($primary, 800) !important;
  }

  ~ .expiry-date-input {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label-wrapper {
      overflow: visible;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label {
        overflow: visible;
      }
    }
  }

  .tw-icon {
    vertical-align: middle !important;
  }

  .mat-expansion-indicator {
    color: mat.get-color-from-palette($primary, 50);
  }

  .tw-primary-button:not([disabled]) {
    background-color: mat.get-color-from-palette($primary, 600);
  }

  .tw-primary {
    background-color: mat.get-color-from-palette($primary, 600);
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: mat.get-color-from-palette($primary, 600) !important;
  }

  .mat-snackbar-container {
    background: mat.get-color-from-palette($primary, 600) !important;
    color: mat.get-color-from-palette($primary, "A700") !important;
  }

  .show-more-link-serif {
    text-decoration: none;
    color: mat.get-color-from-palette($primary, 800) !important;
    font-family: "Open Sans", Georgia, serif;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 20px;
    text-decoration: underline;
  }

  .show-more-link-serif:hover {
    cursor: pointer;
  }

  .show-more-link {
    text-decoration: none;
    color: mat.get-color-from-palette($primary, 800) !important;
    font-family: "Lexend", sans-serif;
    font-size: 18px;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .show-more-link:hover {
    cursor: pointer;
  }

  .show-more-link-complex {
    text-decoration: underline;
    color: mat.get-color-from-palette($primary, 800) !important;
    font-family: "Lexend", sans-serif;
    font-weight: 300;
  }

  .show-more-link-complex :hover {
    cursor: pointer;
  }

  .show-more-link-same-font {
    text-decoration: none;
    color: mat.get-color-from-palette($primary, 800) !important;
    text-decoration: underline;
  }

  .show-more-link-same-font:hover {
    cursor: pointer;
  }

  /* for Calendar. TODO: create separate clean angular theme for all project */
  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background-color: mat.get-color-from-palette($primary, 600) !important;
  }

  .choosen-date {
    background: mat.get-color-from-palette($primary, 600) !important;
    border-radius: 100%;
  }

  .mat-calendar-body-begin-range {
    div {
      background-color: mat.get-color-from-palette($primary, 600) !important;
    }
  }

  .mat-calendar-body-end-range {
    div {
      background-color: mat.get-color-from-palette($primary, 600) !important;
    }
  }

  .mat-calendar-body-disabled.mat-calendar-body-end-range {
    div {
      background-color: #f2545b !important;
    }
  }

  .mat-calendar-body-disabled.mat-calendar-body-begin-range {
    div {
      background-color: #f2545b !important;
    }
  }

  .mat-calendar-body-cell.mat-calendar-body-disabled.mat-calendar-cell-semi-selected {
    div {
      background-color: #f2545b !important;
    }
  }

  .mat-calendar-body-cell.mat-calendar-body-disabled {
    div {
      text-decoration: line-through;
    }
  }

  .mat-calendar-body-cell.mat-calendar-cell-semi-selected {
    div {
      background-color: mat.get-color-from-palette($primary, 600) !important;
      opacity: 0.4 !important;
    }
  }
  button {
    font-family: "Lexend", sans-serif;
  }
  a {
    font-family: "Lexend", sans-serif;
  }

  button:focus,
  button:active:focus,
  button.active:focus {
    outline: none !important;
    outline-style: none !important;
  }

  .tw-tooltip {
    background-color: mat.get-color-from-palette($primary, 500) !important;
    color: white;
    font-size: 14px;
    line-height: 24px;
    max-width: 300px;
    padding: 0px 5px;
    border-radius: 4px;
    border: 2px solid mat.get-color-from-palette($primary, 600);
    pointer-events: none;
  }

  .small-alert {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #f2545b;
  }
  .alert {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #f2545b;
    border: 1px solid;
    padding: 10px;
    width: auto;
  }

  .alert-warning {
    color: #f2545b;
    font-size: small;
    font-style: italic;
    margin-right: 20px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  .ttw-progress .mat-progress-bar-fill::after {
    background-color: mat.get-color-from-palette($primary, 600) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  .ttw-progress .mat-progress-bar-buffer {
    background-color: #90a4ae;
  }

  img {
    border-radius: 12px;
  }

  button {
    border-radius: 12px !important;
    //  color: mat-color($primary, "A700");
  }
  .mat-mdc-button {
    border-radius: 12px !important;
    //color: mat-color($primary, "A700");
  }

  .mat-mdc-raised-button {
    border-radius: 12px !important;
    color: mat.get-color-from-palette($primary, "A700");
  }

  .mat-mdc-chip {
    border-radius: 12px !important;
    color: mat.get-color-from-palette($primary, "A700");
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  .mat-card-image-details {
    padding-bottom: 0 !important;
    border-radius: 12px !important;
  }

  .mat-grid-tile {
    border-radius: 12px !important;
  }

  .mat-grid-list {
    color: mat.get-color-from-palette($primary, "A700");
  }

  .brand-colored {
    color: mat.get-color-from-palette($primary, 600);
  }

  .separator {
    border-bottom: 3px solid mat.get-color-from-palette($primary, 600);
    width: 80px;
    max-width: 80px;
  }

  .title-heading {
    color: mat.get-color-from-palette($primary, 800);
  }
  @media screen and (max-width: 699px) {
    h1 {
      font-weight: 300;
      font-style: normal;
      font-family: "Lexend", sans-serif;
      font-size: 1.8em;
      line-height: 130%;
      letter-spacing: 0.5px;
    }

    h2 {
      font-weight: bold;
      font-style: normal;
      font-family: "Lexend", sans-serif;
      font-size: 1.6em;
      line-height: 2.05em;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (min-width: 700px) {
    h1 {
      font-weight: 300;
      font-style: normal;
      font-family: "Lexend", sans-serif;
      font-size: 2.3em;
      line-height: 130%;
      letter-spacing: 0.5px;
    }

    h2 {
      font-weight: bold;
      font-style: normal;
      font-family: "Lexend", sans-serif;
      font-size: 2em;
      line-height: 2.05em;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  }
  h3 {
    font-weight: normal;
    font-style: normal;
    font-family: "Lexend", sans-serif;
    font-size: 1.5em;
    line-height: 1.55em;

    letter-spacing: 0.5px;
    font-weight: 300;
  }
  h4 {
    font-weight: normal;
    font-style: normal;
    font-family: "Lexend", sans-serif;
    font-weight: 300;
  }

  h5 {
    font-family: "Lexend", Georgia, serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    color: mat.get-color-from-palette($primary, "800");
  }

  p {
    font-family: "Open Sans", Georgia, serif;
    color: mat.get-color-from-palette($primary, "800");
  }
  ul,
  li {
    font-family: "Open Sans", Georgia, serif;
    color: mat.get-color-from-palette($primary, "800");
    font-size: 16px;
    line-height: 26px;
  }

  .unit-details-page {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  .breadcrumb-bar {
    background-color: mat.get-color-from-palette($primary, 400);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    h1 {
      text-align: center;
      color: mat.get-color-from-palette($primary, "800");
      font-size: 1em;
      margin-bottom: 0;
      margin-top: 20px;
    }
    p {
      margin-top: 5px;
      margin-bottom: 15;
      text-align: center;
    }
  }

  .mat-pseudo-checkbox-checked {
    background: mat.get-color-from-palette($primary, 500) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-inner-circle {
    background-color: mat.get-color-from-palette($primary, 500) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($primary, 500) !important;
    background-color: mat.get-color-from-palette($primary, 500) !important;
    color: mat.get-color-from-palette($primary, 500) !important;
  }

  .mat-mdc-optgroup-label {
    color: mat.get-color-from-palette($primary, 800);
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
    font-family: "Lexend", sans-serif;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
  .mat-option-text {
    color: mat.get-color-from-palette($primary, 800);
    font-size: 16px;
    line-height: 25px;
    font-family: "Lexend", sans-serif;
    border-bottom: solid 2px rgba(85, 93, 94, 0.16);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-placeholder {
    color: mat.get-color-from-palette($primary, "A700");
    font-size: 16px;
    line-height: 25px;
    font-family: "Lexend", sans-serif;
    opacity: 0.5;
  }

  .no-decor-link {
    text-decoration: none;
  }

  .show-more-nav-no-padding {
    text-decoration: none;
    color: mat.get-color-from-palette($primary, 800) !important;
    font-family: "Lexend", sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: start;
    cursor: pointer;
  }

  .show-more-nav {
    text-decoration: underline;
    color: mat.get-color-from-palette($primary, 800) !important;
    font-family: "Lexend", sans-serif;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: start;
    .hover-arrows {
      position: relative;
      margin-top: 1px;
      .right-arrow-classic {
        margin-left: 3px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: visible;
      }
      .right-arrow-on-hover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity linear 0.8s;
      }
    }
  }

  .show-more-nav:hover {
    cursor: pointer;
    .hover-arrows {
      position: relative;
      .right-arrow-classic {
        margin-left: 3px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
      }
      .right-arrow-on-hover {
        position: absolute;
        top: 0;
        left: 0;
        visibility: visible;
        opacity: 1;
        transition: opacity linear 0.8s;
      }
    }
  }
} //end primary mixin

.search-params-chip {
  width: 94%;
  padding: 10px 3%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid;
  font-family: "Lexend", sans-serif;

  .search-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .search-summary {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font: inherit;

    .search-summary-location {
      font-weight: 700;
      font: inherit;
    }
    .dates-and-guests {
      font-weight: normal;
      font: inherit;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      opacity: 0.6;
      font-size: small;
    }
  }

  .search-icon {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 30px;
    display: flex;
    align-items: center;
    vertical-align: baseline;
    mat-icon {
      font-size: 30px;
      height: 30px;
      width: 30px;
    }
  }
}

/*******Chat Styles********/
.chat-dialog-container .mat-mdc-dialog-surface {
  overflow-x: hidden;
}
