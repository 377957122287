// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "./custom-component-themes.scss";

$ttw-accent-color: #90d7de; //#ff8600;
$ttw-new-brand-color: #103753;
$ttw-dark-color: #555d5e;
$ttw-dark-color-opacity-60: rgba(85, 93, 94, 0.6);
$ttw-dark-color-opacity-16: rgba(85, 93, 94, 0.16);
$ttw-placeholder: #919191;
$ttw-show-more: #3bb9c4;
$ttw-light-background: #ebeded;
$ttw-offwhite: #f7f7f7;
$white: white;

$ttw-typography: mat.define-typography-config(
  $font-family: "Open Sans, Georgia, serif",
  $headline-1:
    mat.define-typography-level(
      $font-family: "Lexend, sans-serif",
      $font-size: 3em,
      $font-weight: 300,
      $line-height: 130%,
      $letter-spacing: 0.5px,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-family: "Lexend, sans-serif",
      $font-size: 2em,
      $font-weight: 300,
      $line-height: 2.05em,
      $letter-spacing: 0.5px,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-family: "Lexend, sans-serif",
      $font-size: 1.5em,
      $font-weight: 300,
      $line-height: 1.55em,
      $letter-spacing: 0.5px,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: "Open Sans, Georgia, serif",
      $font-size: 16px,
      $line-height: 24px,
    ),
  $button:
    mat.define-typography-level(
      $font-family: "Lexend, sans-serif",
      $font-size: 16px,
      $line-height: 24px,
    ),
);

$mat-ttw: (
  50: $white,
  100: $ttw-offwhite,
  200: $ttw-offwhite,
  300: $ttw-light-background,
  400: $ttw-light-background,
  500: $ttw-accent-color,
  600: $ttw-accent-color,
  700: $ttw-show-more,
  800: $ttw-new-brand-color,
  900: $ttw-dark-color-opacity-16,
  A100: $ttw-dark-color-opacity-60,
  A200: $ttw-placeholder,
  A400: $ttw-dark-color,
  A700: $ttw-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-ttw-warn: (
  50: #f2545b,
  100: #f2545b,
  200: #f2545b,
  300: #f2545b,
  400: #f2545b,
  500: #f2545b,
  600: #f2545b,
  700: #f2545b,
  800: #f2545b,
  900: #f2545b,
  A100: #f2545b,
  A200: #f2545b,
  A400: #f2545b,
  A700: #f2545b,
  contrast: (
    50: #f2545b,
    100: #f2545b,
    200: #f2545b,
    300: #f2545b,
    400: #f2545b,
    500: #f2545b,
    600: #f2545b,
    700: #f2545b,
    800: #f2545b,
    900: #f2545b,
    A100: #f2545b,
    A200: #f2545b,
    A400: #f2545b,
    A700: #f2545b,
  ),
);

@function my-mat-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

// You can put any color here. I've chosen mat-color($my-app-primary, 700)
$ttw-foreground: my-mat-light-theme-foreground(
  mat.get-color-from-palette($mat-ttw, 800)
);

$ttw-primary: mat.define-palette($mat-ttw, 800);
$ttw-accent: mat.define-palette($mat-ttw, 500);
$ttw-warn: mat.define-palette($mat-ttw-warn);

/*$ttw-theme: mat.define-light-theme($ttw-primary, $ttw-accent, $ttw-warn);

$ttw-theme-custom: map-merge(
  $ttw-theme,
  (
    foreground: $ttw-foreground,
  )
);*/

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$twbooking-theme: mat.define-light-theme(
  (
    color: (
      primary: $ttw-primary,
      accent: $ttw-accent,
      warn: $ttw-warn,
    ),
    typography: $ttw-typography,
    density: 0,
    foreground: $ttw-foreground,
  )
);

//@include mat.all-legacy-component-themes($ttw-theme-custom);
.twbooking-theme {
  @include cust-theme($twbooking-theme);
  @include mat.core-theme($twbooking-theme);
  @include mat.all-component-themes($twbooking-theme);
  @include mat.select-color($twbooking-theme);
}

/*** Laurie Theme ***/

$laurie-accent-color: #554998; //#ff8600;
$laurie-dark-color: #203864; //#8b4c39; //#555d5e #7b3f00;
$laurie-dark-color-opacity-60: rgba(
  32,
  56,
  100,
  0.6
); //rgba(139, 76, 57, 0.6); // rgba(85, 93, 94, 0.6);
$laurie-dark-color-opacity-16: rgba(32, 56, 100, 0.16);
$laurie-show-more: #554998;
$laurie-light-background: #ebeded; // #ffe4c4; //#ebeded
$laurie-offwhite: white;
$white: white;

$mat-laurie: (
  50: $white,
  100: $laurie-offwhite,
  200: $laurie-offwhite,
  300: $laurie-light-background,
  400: $laurie-light-background,
  500: $laurie-accent-color,
  600: $laurie-accent-color,
  700: $laurie-show-more,
  800: $laurie-accent-color,
  900: $laurie-dark-color-opacity-16,
  A100: $laurie-dark-color-opacity-16,
  A200: $laurie-dark-color-opacity-60,
  A400: $laurie-dark-color,
  A700: $laurie-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function laurie-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$laurie-foreground: laurie-theme-foreground(
  mat.get-color-from-palette($mat-laurie, A700)
);

$laurie-primary: mat.define-palette($mat-laurie);
$laurie-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$laurie-warn: mat.define-palette(mat.$red-palette);

$laurie-theme: mat.define-light-theme(
  (
    color: (
      primary: $laurie-primary,
      accent: $laurie-accent,
      warn: $laurie-warn,
    ),
  )
);

.laurie-theme {
  $laurie-theme-custom: map-merge(
    $laurie-theme,
    (
      foreground: $laurie-foreground,
    )
  );
  @include cust-theme($laurie-theme-custom);
  @include mat.all-component-colors($laurie-theme-custom);
}

/*** smartflyer Theme ***/

$smartflyer-accent-color: rgb(21, 61, 140);
$smartflyer-light-accent: rgb(21, 61, 140); // rgb(245, 245, 245);
$smartflyer-dark-summary: rgb(21, 61, 140);
$smartflyer-dark-color: rgb(45, 45, 45);
$smartflyer-dark-color-opacity-60: rgba(45, 45, 45, 0.6);
$smartflyer-dark-color-opacity-16: rgba(45, 45, 45, 0.16);
$smartflyer-show-more: rgb(21, 61, 140);
$smartflyer-light-background: rgb(245, 245, 245);
$smartflyer-offwhite: white;
$white: white;

$mat-smartflyer: (
  50: $white,
  100: $smartflyer-offwhite,
  200: $smartflyer-offwhite,
  300: $smartflyer-light-background,
  400: $smartflyer-light-background,
  500: $smartflyer-light-accent,
  600: $smartflyer-accent-color,
  700: $smartflyer-show-more,
  800: $smartflyer-accent-color,
  900: $smartflyer-dark-color-opacity-16,
  A100: $smartflyer-dark-color-opacity-16,
  A200: $smartflyer-dark-color-opacity-60,
  A400: $smartflyer-dark-summary,
  A700: $smartflyer-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function smartflyer-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$smartflyer-foreground: smartflyer-theme-foreground(
  mat.get-color-from-palette($mat-smartflyer, A700)
);

$smartflyer-primary: mat.define-palette($mat-smartflyer);
$smartflyer-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$smartflyer-warn: mat.define-palette(mat.$red-palette);

$smartflyer-theme: mat.define-light-theme(
  (
    color: (
      primary: $smartflyer-primary,
      accent: $smartflyer-accent,
      warn: $smartflyer-warn,
    ),
  )
);

.smartflyer-theme {
  $smartflyer-theme-custom: map-merge(
    $smartflyer-theme,
    (
      foreground: $smartflyer-foreground,
    )
  );
  //@include mat.all-legacy-component-themes($smartflyer-theme-custom);
  @include cust-theme($smartflyer-theme-custom);
  @include mat.all-component-colors($smartflyer-theme-custom);
}

/*** Amanda Theme ***/

$amanda-accent-color: rgb(40, 134, 127); //#ff8600;
$amanda-dark-color: rgb(45, 62, 80); //#8b4c39; //#555d5e #7b3f00;
$amanda-dark-color-opacity-60: rgba(
  45,
  62,
  80,
  0.6
); //rgba(139, 76, 57, 0.6); // rgba(85, 93, 94, 0.6);
$amanda-dark-color-opacity-16: rgba(45, 62, 80, 0.16);
$amanda-show-more: rgb(40, 134, 127);
$amanda-light-background: rgb(240, 244, 250);
$amanda-offwhite: white;
$white: white;

$mat-amanda: (
  50: $white,
  100: $amanda-offwhite,
  200: $amanda-offwhite,
  300: $amanda-light-background,
  400: $amanda-light-background,
  500: $amanda-accent-color,
  600: $amanda-accent-color,
  700: $amanda-show-more,
  800: $amanda-accent-color,
  900: $amanda-dark-color-opacity-16,
  A100: $amanda-dark-color-opacity-16,
  A200: $amanda-dark-color-opacity-60,
  A400: $amanda-dark-color,
  A700: $amanda-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function amanda-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$amanda-foreground: amanda-theme-foreground(
  mat.get-color-from-palette($mat-amanda, A700)
);

$amanda-primary: mat.define-palette($mat-amanda);
$amanda-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$amanda-warn: mat.define-palette(mat.$red-palette);

$amanda-theme: mat.define-light-theme(
  (
    color: (
      primary: $amanda-primary,
      accent: $amanda-accent,
      warn: $smartflyer-warn,
    ),
  )
);

.amanda-theme {
  $amanda-theme-custom: map-merge(
    $amanda-theme,
    (
      foreground: $amanda-foreground,
    )
  );
  // @include mat.all-legacy-component-themes($amanda-theme-custom);
  @include cust-theme($amanda-theme-custom);
  @include mat.all-component-colors($amanda-theme-custom);
}
/*** Neutral Template ***/

$neutral-accent-color: #a3c5d4; //163, 197, 212
$neutral-dark-color: #3e6f84;
$neutral-dark-color-opacity-60: rgba(62, 111, 132, 0.6);
$neutral-dark-color-opacity-16: rgba(62, 111, 132, 0.16);
$neutral-show-more: #93bbcc;
$neutral-light-background: #f0f5f8;
$neutral-offwhite: white;
$white: white;

$mat-neutral: (
  50: $white,
  100: $neutral-offwhite,
  200: $neutral-offwhite,
  300: $neutral-light-background,
  400: $neutral-light-background,
  500: $neutral-accent-color,
  600: $neutral-accent-color,
  700: $neutral-show-more,
  800: $neutral-accent-color,
  900: $neutral-dark-color-opacity-16,
  A100: $neutral-dark-color-opacity-16,
  A200: $neutral-dark-color-opacity-60,
  A400: $neutral-dark-color,
  A700: $neutral-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function neutral-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$neutral-foreground: neutral-theme-foreground(
  mat.get-color-from-palette($mat-neutral, A700)
);

$neutral-primary: mat.define-palette($mat-neutral);
$neutral-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$neutral-warn: mat.define-palette(mat.$red-palette);

$neutral-theme: mat.define-light-theme(
  (
    color: (
      primary: $neutral-primary,
      accent: $neutral-accent,
      warn: $neutral-warn,
    ),
  )
);

.neutral-theme {
  $neutral-theme-custom: map-merge(
    $neutral-theme,
    (
      foreground: $neutral-foreground,
    )
  );
  // @include mat.all-legacy-component-themes($neutral-theme-custom);
  @include cust-theme($neutral-theme-custom);
  @include mat.all-component-colors($neutral-theme-custom);
}

/*** jettsettravel Theme ***/

$jettsettravel-accent-color: rgb(35, 112, 153);
$jettsettravel-light-accent: rgb(35, 112, 153);
$jettsettravel-dark-summary: rgb(9, 46, 64);
$jettsettravel-dark-color: rgb(9, 46, 64);
$jettsettravel-dark-color-opacity-60: rgba(9, 46, 64, 0.6);
$jettsettravel-dark-color-opacity-16: rgba(9, 46, 64, 0.16);
$jettsettravel-show-more: rgb(35, 112, 153);
$jettsettravel-light-background: rgb(231, 240, 251);
$jettsettravel-offwhite: white;
$white: white;

$mat-jettsettravel: (
  50: $white,
  100: $jettsettravel-offwhite,
  200: $jettsettravel-offwhite,
  300: $jettsettravel-light-background,
  400: $jettsettravel-light-background,
  500: $jettsettravel-light-accent,
  600: $jettsettravel-accent-color,
  700: $jettsettravel-show-more,
  800: $jettsettravel-accent-color,
  900: $jettsettravel-dark-color-opacity-16,
  A100: $jettsettravel-dark-color-opacity-16,
  A200: $jettsettravel-dark-color-opacity-60,
  A400: $jettsettravel-dark-summary,
  A700: $jettsettravel-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function jettsettravel-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$jettsettravel-foreground: jettsettravel-theme-foreground(
  mat.get-color-from-palette($mat-jettsettravel, A700)
);

$jettsettravel-primary: mat.define-palette($mat-jettsettravel);
$jettsettravel-accent: mat.define-palette(
  mat.$orange-palette,
  A200,
  A100,
  A400
);
$jettsettravel-warn: mat.define-palette(mat.$red-palette);

$jettsettravel-theme: mat.define-light-theme(
  (
    color: (
      primary: $jettsettravel-primary,
      accent: $jettsettravel-accent,
      warn: $jettsettravel-warn,
    ),
  )
);

.jettsettravel-theme {
  $jettsettravel-theme-custom: map-merge(
    $jettsettravel-theme,
    (
      foreground: $jettsettravel-foreground,
    )
  );
  //@include mat.all-legacy-component-themes($jettsettravel-theme-custom);
  @include cust-theme($jettsettravel-theme-custom);
  @include mat.all-component-colors($jettsettravel-theme-custom);
}

/*** packhappytravel Theme ***/

$packhappytravel-accent-color: #4093a4; //rgb(71, 201, 229);
$packhappytravel-light-accent: #ed551d;
$packhappytravel-dark-summary: rgb(6, 31, 60);
$packhappytravel-dark-color: rgb(6, 31, 60);
$packhappytravel-dark-color-opacity-60: rgba(6, 31, 60, 0.6);
$packhappytravel-dark-color-opacity-16: rgba(6, 31, 60, 0.16);
$packhappytravel-show-more: rgb(71, 201, 229);
$packhappytravel-light-background: rgb(252, 252, 252);
$packhappytravel-offwhite: white;
$white: white;

$mat-packhappytravel: (
  50: $white,
  100: $packhappytravel-offwhite,
  200: $packhappytravel-offwhite,
  300: $packhappytravel-light-background,
  400: $packhappytravel-light-background,
  500: $packhappytravel-light-accent,
  600: $packhappytravel-accent-color,
  700: $packhappytravel-show-more,
  800: $packhappytravel-accent-color,
  900: $packhappytravel-dark-color-opacity-16,
  A100: $packhappytravel-dark-color-opacity-16,
  A200: $packhappytravel-dark-color-opacity-60,
  A400: $packhappytravel-dark-summary,
  A700: $packhappytravel-dark-color,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

@function packhappytravel-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.3),
    dividers: rgba($color, 0.3),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.6),
    disabled-text: rgba($color, 0.6),
    hint-text: rgba($color, 0.6),
    secondary-text: rgba($color, 0.8),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 1),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$packhappytravel-foreground: packhappytravel-theme-foreground(
  mat.get-color-from-palette($mat-packhappytravel, A700)
);

$packhappytravel-primary: mat.define-palette($mat-packhappytravel);
$packhappytravel-accent: mat.define-palette(
  mat.$orange-palette,
  A200,
  A100,
  A400
);
$packhappytravel-warn: mat.define-palette(mat.$red-palette);

$packhappytravel-theme: mat.define-light-theme(
  (
    color: (
      primary: $packhappytravel-primary,
      accent: $packhappytravel-accent,
      warn: $packhappytravel-warn,
    ),
  )
);

.packhappytravel-theme {
  $packhappytravel-theme-custom: map-merge(
    $packhappytravel-theme,
    (
      foreground: $packhappytravel-foreground,
    )
  );
  // @include mat.all-legacy-component-themes($packhappytravel-theme-custom);
  @include cust-theme($packhappytravel-theme-custom);
  @include mat.all-component-colors($packhappytravel-theme-custom);
}
